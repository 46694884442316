<template>
  <div>
    <el-form :inline="true" :model="form" class="demo-form-inline" style="margin-left: 2%">
      <el-form-item style="margin-left: 2%">
        <el-radio-group size="medium" disabled >
          <el-radio-button>押&emsp;金&emsp;余&emsp;额&emsp;:&emsp;{{ cashPledge }} ¥</el-radio-button>
          <el-radio-button>预&nbsp;付&nbsp;款&nbsp;余&nbsp;额&emsp;:&emsp;{{ advanceCharge }} ¥</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.orderNo" placeholder="订单编号" size="medium" class="searchParam"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="medium" @click="search">查询</el-button>
      </el-form-item>
      <el-form-item style="margin-left: 5%">
        <el-radio-group v-model="form.orderState" size="medium" @input="queryStoreOrderList">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button :label="0">待付款</el-radio-button>
          <el-radio-button :label="1">待发货</el-radio-button>
          <el-radio-button :label="2">已发货</el-radio-button>
          <el-radio-button :label="3">已退款</el-radio-button>
          <el-radio-button :label="4">已取消</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-table
          :data="table.storeOrderList"
          border
          max-height="700"
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :default-sort="{prop: 'id', order: 'descending'}"
          v-loading="loading"
          style="width: 100%">
        <el-table-column prop="createDate" label="下单时间" align="center"/>
        <el-table-column prop="payDate" label="支付时间" align="center"/>
        <el-table-column prop="orderNo" label="订单编号" align="center" />
        <el-table-column prop="empName" label="下单人" align="center" />
        <el-table-column prop="payChannel" label="支付方式" align="center" >
          <template slot-scope="scope">
            {{scope.row.payChannel===1?'支付宝':'预付款余额'}}
          </template>
        </el-table-column>
        <el-table-column prop="orderState" label="订单状态" align="center" >
          <template slot-scope="scope">
            <p v-if="scope.row.orderState===0" style="background-color: #dcdc9e;color: black">待付款</p>
            <p v-if="scope.row.orderState===1" style="background-color: #6b93ea;color: black">待发货</p>
            <p v-if="scope.row.orderState===2" style="background-color: #96d096;color: black">已发货</p>
            <p v-if="scope.row.orderState===3" style="background-color: #cb4646;color: black">已退款</p>
            <p v-if="scope.row.orderState===4" style="background-color: #ce8989;color: black">已取消</p>
          </template>
        </el-table-column>
        <el-table-column prop="totalAmount" label="订单总价" align="center" />

        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group >
              <el-button type="primary" size="mini" @click="openOrderStyleList(scope.row)">查看订单</el-button>
              <el-button v-if="scope.row.orderState===1" style="background-color: #e0ba5b;border-color: #e0ba5b;color: black"
                         size="mini" @click="cancelOrder(scope.row)">取消并退款</el-button>
              <el-button v-if="scope.row.orderState===0" style="background-color: #e0e05b;border-color: #e0e05b;color: black"
                         size="mini" @click="cancelOrder(scope.row)">取消订单</el-button>
              <el-button v-if="scope.row.orderState===3||scope.row.orderState===4" style="background-color: #da1212;border-color: #da1212;color: black"
                         size="mini" @click="delByOrderId(scope.row)">删除订单</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <orderStyle v-if="dialog.orderStyleState" :storeOrder="dialog.storeOrder" :state="dialog.orderStyleState" @close="close"/>
  </div>
</template>

<script>
import orderStyle from "@/pages/store/order/store-order-style-list";

export default {
  name: "store-order-list",
  data() {
    return {
      table: {
        storeOrderList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      form:{
        orderNo:"",
        orderState:"0",
      },
      loading:true,
      tenantCrop: localStorage.getItem("tenantCrop"),
      dialog: {
        orderStyleState: false,
        storeOrder: {},
      },

      cashPledge:0,
      advanceCharge:0,
    }
  },
  components: {
    orderStyle
  },
  created() {
    this.queryStoreOrderList();
    this.queryAdvanceCharge();
  },
  methods: {
    search:function (){
      this.loading=true;
      this.queryStoreOrderList();
    },
    //查询客户列表
    queryStoreOrderList: function () {
      this.$axios({
        method: "GET",
        url: "/storeOrder/queryList",
        params: {
          tenantCrop:this.tenantCrop,
          orderNo:this.form.orderNo,
          orderState:this.form.orderState,
        }
      }).then(response => {
        this.loading=false;
        this.table.storeOrderList = response.data.data;
      })
    },
    openOrderStyleList(data){
      this.dialog.orderStyleState = true
      this.dialog.storeOrder = data;
    },
    //查询预付款
    queryAdvanceCharge(){
      this.$axios({
        method: "GET",
        url: "/tenant/queryTenantInfo",
        params:{
          tenantCrop:this.tenantCrop
        }
      }).then(response => {
        this.cashPledge=response.data.data.cashPledge;
        this.advanceCharge=response.data.data.advanceCharge;
      })
    },
    //取消订单
    cancelOrder(data){
      this.$confirm("是否取消订单？款项将退回账户预付款", '取消订单:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/storeOrder/cancelOrder",
          data: {
            id: data.id,
          }
        }).then(response => {
          if (response.data.code === 200){
            this.$message.success("取消订单成功!");
            this.queryStoreOrderList();
            return;
          }
          this.$message.error(response.data.msg);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //删除订单
    delByOrderId(data){
      this.$confirm("是否删除此订单？", '删除订单:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/storeOrder/delById",
          data: {
            id: data.id,
          }
        }).then(response => {
          if (response.data.code === 200){
            this.$message.success("删除成功!");
            this.queryStoreOrderList();
            return;
          }
          this.$message.error(response.data.msg);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryStoreOrderList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryStoreOrderList()
    },
    close: function (state) {
      this.dialog.orderStyleState = state;
      this.queryStoreOrderList();
    },
  }
}
</script>

<style scoped>
.el-radio-group >>> .el-radio-button__orig-radio:disabled+.el-radio-button__inner{
  font-weight: bold;
  color:red
}
</style>